import React, { useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container, Row, Col } from '../components/grid'
import PageHeader from '../components/page-header'
import styled, { Box, css, up } from '@xstyled/styled-components'
import { Text, Emphasis } from '../components/text'
import SubHeader from '../components/sub-header'
import Button from '../components/button'
import * as Yup from 'yup'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import shapesBg from '../images/green-circle-pink-semicircle.svg'

const BgContainer = styled(Box)`
  ${up(
    'md',
    css`
      background-image: url(${shapesBg});
      background-repeat: no-repeat;
      background-position: bottom left;
      background-size: 710px;
    `
  )}
`
const Error = styled(Text)(
  () => css`
    color: red;
    font-size: 1rem;
  `
)

const SubmitBox = styled(Box)`
  margin: 5 0;
`

const Label = styled.label(
  () => css`
    color: body;
    font-size: 1rem;
    display: block;
    margin-bottom: 0.5rem;
  `
)

const Input = styled.input(
  () => css`
    font-size: 19px;
    margin-bottom: 8px;
    padding: 1rem;
    background-color: inputGray;
    display: block;
    width: 100%;
    border-width: 0;
    border-style: none;
  `
)

const TextArea = styled.textarea(
  () => css`
    font-size: 19px;
    padding: 1rem;
    background-color: inputGray;
    display: block;
    width: 100%;
    border-width: 0;
    border-style: none;
  `
)

const FormGroup = styled.box(
  () => css`
    margin-bottom: 1.5rem;
  `
)

const DropIn = () => (
  <>
    <SEO title="Drop-In" />
    <Layout>
      <PageHeader>Drop-In</PageHeader>

      <BgContainer py="5">
        <Container>
          <Row>
            <Col col={{ lg: 8 / 12 }} mx={{ lg: 'auto' }}>
              <SubHeader
                mb="5"
                textAlign={{
                  xs: 'center',
                }}
              >
                What’s that one burning question you want help with?
              </SubHeader >
              <Text textAlign={{
                  xs: 'center',
                }}>
                We want to help as many founders as possible. Drop-In allows you to ask one concise question that you need help with, and we will personally get back to you with a response.
              </Text>
              <ContactForm />

            </Col>
          </Row>
        </Container>
      </BgContainer>
    </Layout>
  </>
)

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false)
  const onSubmit = async (values) => {
    try {
      await fetch(process.env.GATSBY_CONTACT_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
      setSubmitted(true)
    } catch (e) {
      console.log(e)
    }
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Please enter your name'),
    email: Yup.string().email().required('Please enter an email address'),
    message: Yup.string().required('Please leave us a message'),
  })
  const initialValues = {
    name: '',
    email: '',
    message: '',
    reason: 'Drop in'
  }

  return (
    <>
      {!submitted && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isValid, dirty, isSubmitting }) => (
            <Form>
              <FormGroup>
                <Label htmlFor="name">Name</Label>
                <Field
                  as={Input}
                  name="name"
                  id="name"
                  type="text"
                  placeholder="John Smith"
                  required
                />
                <ErrorMessage component={Error} name="name" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="email">Email</Label>
                <Field
                  as={Input}
                  name="email"
                  id="email"
                  type="email"
                  placeholder="johnsmith@example.com"
                  required
                />
                <ErrorMessage component={Error} name="email" />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="message">Message</Label>
                <Field
                  as={MessageTextArea}
                  name="message"
                  id="message"
                  rows="7"
                  placeholder="Enter your message..."
                  required

                />
                <ErrorMessage component={Error} name="message" />
              </FormGroup>

              <Emphasis>
                Please Note: We aim to reply to all questions within 2 weeks of
                submission. One question per startup.
              </Emphasis>

              <SubmitBox>
                <Button
                  type="submit"
                  disabled={!(isValid && dirty) || isSubmitting}
                  ml="auto"
                >
                  Come play
                </Button>
              </SubmitBox>
            </Form>
          )}
        </Formik>
      )}
      {submitted && (
        <Box>
          <Text my={6} textAlign="center">
          Thanks. We’ve received your question. Bear with us on a response!
          </Text>
        </Box>
      )}
    </>
  )
}

const MessageTextArea = ({ value, ...props }) => {
  const maxLength = 500
  return (
    <>
    <Box position="relative">
      <TextArea maxLength={maxLength} value={value} {...props} />
      <Text my={0} fontSize='0.75rem' position="absolute" right={0} bottom={0} pb={2} pr={2}>{maxLength - (value.length || 0)} characers left</Text>
      </Box>
    </>
  )
}

export default DropIn
